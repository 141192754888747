<template>
    <b-overlay :show="!ready">
        <div class="information-block">
            <div><span>Размер портфеля (руб)</span><span class="value">{{ loansTotalSum | numberFormat(0, '.', ' ') }}</span></div>
<!--            <div><span>Собрано за день (руб)</span><span class="value">8 700 500</span></div>-->
<!--            <div><span>Долго без обработки</span><span class="value">8 700 500</span></div>-->
            <div><span>Займов в портфеле (шт)</span><span class="value">{{ loansTotalCnt | numberFormat(0, '.', ' ') }}</span></div>
<!--            <div><span>Новые займы за сегодня (шт)</span><span class="value">8 700 500</span></div>-->
<!--            <div><span>Обработано за день</span><span class="value">8 700 500</span></div>-->
<!--            <div><span>СМС за сегодня</span><span class="value">8 700 500</span></div>-->
<!--            <div><span>Звонков за сегодня</span><span class="value">8 700 500</span></div>-->
        </div>
    </b-overlay>
</template>

<script>
export default {
    data() {
        return {
            loansTotalSum: null,
            loansTotalCnt: null,
            ready: false,
        };
    },
    mounted() {
        this.jsonRpc("admin.loans.userStat").then(async (response) => {
            this.loansTotalSum = response.loans_total_sum;
            this.loansTotalCnt = response.loans_total_cnt;
            this.ready = true;
        });
    },
}
</script>

<style lang="scss">
.information-block {
    display: flex;
    margin: -5px;
    margin-bottom: 15px;
    font-size: 75%;
    justify-content: space-between;

    div {
        width: 100%;
        display: flex;
        padding: 5px;
        justify-content: space-between;
    }

    span {
        padding: 2px;
    }

    span.value {
        background-color: lightgray;
        white-space: nowrap;
    }
}
</style>
