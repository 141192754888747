<template>
    <div>
        <information />
        <list :user-filter="false" />
    </div>
</template>

<script>
import List from "./List";
import Information from "./Information";

export default {
    components: {List,Information},
};
</script>
